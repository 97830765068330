<template>
  <div class="d-flex flex-column justify-center align-center fill-height px-2">
    <LoginForm />
  </div>
</template>

<script>
import LoginForm from '@/components/forms/LoginForm'

export default {
  name: 'Login',

  components: {
    LoginForm
  },

  data: () => ({})
}
</script>

<style lang="scss" scoped>
</style>
