<template>
  <v-snackbar
    v-model="controlAlert"
    :timeout="timeout"
    :color="alertOptions.color"
    style="z-index: 2001"
  >
    <p class="text-center pa-0 ma-0 subtitle-2 white--text">
      {{ alertOptions.text }}
    </p>
  </v-snackbar>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'AppAlert',

  data: () => ({
    timeout: 3000
  }),

  computed: {
    ...mapState(['alertOptions']),
    ...mapGetters({
      isAlertOpen: 'alertOpen'
    }),
    controlAlert: {
      get () {
        return this.isAlertOpen
      },
      set (val) {
        this.handleAlert({ value: val })
      }
    }
  },
  methods: {
    ...mapActions(['handleAlert'])
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-snack__action {
  display: none;
}
</style>
