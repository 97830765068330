<template>
  <div class="d-flex flex-row justify-space-between align-center" style="width: 100%">
    <div class="d-flex" style="width: 100%;">
      <v-text-field
        placeholder="Buscar..."
        height="56px"
        width="500px"
        solo
        background-color="#F5F5F7"
        flat
        hide-details
        class="text-subtitle-1 font-weight-medium rounded searchBar"
        v-model="searchBar"
        @keyup.enter="debounceHeaderSearch(search($route.name, searchBar))"
      >
      </v-text-field>
      <v-btn
        x-small
        height="56px"
        width="56px"
        elevation="0"
        class="d-flex justify-center align-center rounded mr-2  bg__icon align-self-start justify-self-start"
        @click="debounceHeaderSearch(search($route.name, searchBar))"
      >
        <v-icon size="28">mdi-magnify</v-icon>
      </v-btn>
    </div>
    <v-btn
      v-if="userLogged"
      @click="userDisconnect"
      color="transparent"
      elevation="0"
      small
      class="text-capitalize text-subtitle-2 text--secondary"
    >
      Cerrar Sesión
      <v-icon class="ml-2" size="26">mdi-exit-to-app</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { EventBus } from '@/EventBus.js'
import { mapActions, mapGetters, mapState } from 'vuex'
import { userLogout } from '@/services/users'
import DebounceHeaderSearch from '@/mixins/DebounceHeaderSearch'

export default {
  name: 'HeaderContent',

  data: () => ({
    alertMessage: '',
    alertColor: '',
    searchBar: ''
  }),

  computed: {
    ...mapState(['userToken', 'textSearch']),
    ...mapGetters(['userLogged', 'getTextSearch'])
  },

  methods: {
    ...mapActions(['signout', 'handleLoading', 'handleAlert']),

    search (routeName, input) {
      EventBus.$emit(`search${routeName}`, input)
    },

    async userDisconnect () {
      try {
        this.handleLoading(true)
        const res = await userLogout(this.userToken)
        this.signout()
        this.alertMessage = res.data.message
        this.alertColor = 'success'
      } catch (error) {
        this.alertColor = 'error'
        if (error.status === 401) {
          this.signout()
          this.alertMessage = 'Sesión caducada'
        } else {
          this.alertMessage = error.data.message
        }
      } finally {
        this.handleLoading(false)
        this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
      }
    }
  },
  mixins: [DebounceHeaderSearch]
}
</script>

<style lang="scss" scoped>
.searchBar {
  max-width: 30%;
}
</style>
