<template>
  <form
    @submit.prevent="submit()"
    class="d-flex flex-column"
    novalidate
    autocomplete="off"
  >
    <v-card
      class="mx-auto py-8"
      :class="$vuetify.breakpoint.xs ? 'px-2' : 'px-8'"
      width="378"
      elevation="0"
      rounded="lg"
      color="grey"
    >
      <v-card-text class="py-0">
        <v-card-title class="pl-0">Iniciar sesion</v-card-title>
          <v-text-field
            v-model="form.email"
            label="Email"
            :error-messages="emailErrors"
            required
            dense
            filled
          ></v-text-field>

          <v-text-field
            v-model="form.password"
            label="Contraseña"
            :error-messages="passwordErrors"
            required
            dense
            filled
            :append-icon="showPassword ? eye : eye_off"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <a href="#" class="pb-4 secondary--text">¿Olvidaste tu contraseña?</a>
      </v-card-text>
    </v-card>
    <div class="text-center">
      <v-btn
        type="submit"
        dark
        class="rounded-xl body-1 mt-16 px-16"
        color="primary"
      >
        Ingresar
      </v-btn>
    </div>
  </form>
  
</template>

<script>
import { mapActions } from 'vuex'
import { userLogin } from '@/services/users'
import { required, email } from 'vuelidate/lib/validators'
import { mdiEye, mdiEyeOff } from '@mdi/js'

export default {
  name: 'LoginForm',

  props: {
    validationsProps: {
      type: Object,
      default: () => ({
        form: {
          email: { required, email },
          password: { required }
        }
      })
    }
  },

  data: () => ({
    showPassword: false,
    eye: mdiEye,
    eye_off: mdiEyeOff,
    alertMessage: '',
    alertColor: '',
    form: {
      email: '',
      password: ''
    }
  }),

  validations () {
    return this.validationsProps
  },

  computed: {
    emailErrors () {
      const errors = []
      if (!this.$v.form.email.$dirty) return errors
      !this.$v.form.email.email && errors.push('Ingrese un Email válido')
      !this.$v.form.email.required && errors.push('Porfavor completa este campo')
      return errors
    },

    passwordErrors () {
      const errors = []
      if (!this.$v.form.password.$dirty) return errors
      !this.$v.form.password.required && errors.push('Porfavor completa este campo')
      return errors
    }
  },

  methods: {
    ...mapActions(['saveUser', 'handleLoading', 'handleAlert']),

    submit () {
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      this.login()
    },

    async login () {
      try {
        this.handleLoading(true)
        const res = await userLogin({
          email: this.form.email,
          password: this.form.password
        })
        this.saveUser({ token: res.data.response.token, user: res.data.response.user })
        this.alertMessage = res.data.message
        this.alertColor = 'success'
        this.$router.push({ name: 'Users' }).catch(() => {})
      } catch (error) {
        this.alertMessage = error.data?.message
        this.alertColor = 'error'
      } finally {
        this.handleLoading(false)
        this.handleAlert({ value: true, text: this.alertMessage, color: this.alertColor })
      }
    }

  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background-color: transparent !important;
    background-color: transparent !important;
  }
}
</style>
