import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const isLoggedIn = () => {
  const authToken = localStorage.getItem('userInfoToken')
  const parseToken = JSON.parse(authToken)
  return !!parseToken?.token
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: {
      allowAllUsers: true
    },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      allowAllUsers: true
    },
    component: () => import('../views/Users.vue')
  },
  {
    path: '/publications',
    name: 'Publications',
    meta: {
      allowAllUsers: true
    },
    component: () => import('../views/Publications.vue')
  },
  {
    path: '/rentals',
    name: 'Rentals',
    meta: {
      allowAllUsers: true
    },
    component: () => import('../views/Rentals.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      allowAllUsers: true
    },
    component: () => import('../views/Profile.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/' && isLoggedIn()) {
    next({ name: 'Users' })
  } else if (to.name === 'Login' && isLoggedIn()) {
    next({ name: 'Users' })
  } else if (!to.meta.allowAllUsers && !isLoggedIn()) {
    next({
      name: 'Login'
    })
  } else {
    next()
  }
})

export default router
