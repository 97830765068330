<template>
  <v-overlay :value="showLoading" :opacity="bgOpacity" z-index="2000">
    <div class="text-center mb-16">
      <v-progress-circular
        :size="circleSize"
        :color="circleColor"
        indeterminate
      ></v-progress-circular>
      <p class="mt-5">{{message}}</p>
    </div>
  </v-overlay>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AppLoading',

  props: {
    circleSize: { type: Number, default: 50 },
    circleColor: { type: String, default: 'white' },
    message: { type: String, default: 'Cargando, por favor espere...' },
    bgOpacity: { type: Number, default: 0.70 }
  },

  data: () => ({}),

  computed: {
    ...mapState(['showLoading'])
  }
}
</script>

<style lang="scss" scoped>
</style>
