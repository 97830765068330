<template>
  <v-app>
    <Header v-if="userLogged"/>
    <Login v-if="!userLogged"/>
    <v-main v-else>
      <router-view />
      <MenuNav />
    </v-main>
    <AppLoading v-if="showLoading"/>
    <AppAlert />
  </v-app>
</template>

<script>
import Login from './views/Login.vue';
import Header from './components/layout/Header.vue';
import MenuNav from './components/layout/MenuNav.vue';
import AppLoading from './components/layout/loading/AppLoading.vue'
import AppAlert from './components/layout/toast/AppAlert.vue'
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: "App",
  data: () => ({
    isLoggedIn: false
  }),
  components: { Login, Header, MenuNav, AppLoading, AppAlert },
  methods: {
    ...mapActions(['checkUserLogged'])
  },
  created () {
    this.checkUserLogged()
  },
  computed: {
    ...mapGetters(['userLogged', 'userRoles']),
    ...mapState(['showLoading'])
  },
  
};
</script>
<style lang="scss">
@import "./assets/scss/vars.scss";

* {
  font-family: $body-font-family !important;
}
a {
  text-decoration: none;
}

</style>
