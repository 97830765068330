import { render, staticRenderFns } from "./MenuNavHeader.vue?vue&type=template&id=2c0d2db0&scoped=true&"
import script from "./MenuNavHeader.vue?vue&type=script&lang=js&"
export * from "./MenuNavHeader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c0d2db0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VDivider,VImg})
