<template>
  <v-list flat class="px-3" nav>
    <v-list-item-group color="primary">
      <v-list-item v-for="(item, i) in items" :key="i" :to="item.pathName"
       :class="{'active': checkRouteActive(item.pathName)}">
        <v-list-item-icon>
          <v-icon style="'color': aqua !important; 'fill': aqua !important;" v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.text" class="text-left font-weight-medium"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mdiAccount, mdiHomeGroup, mdiStorefrontOutline } from '@mdi/js'

export default {
  name: 'MenuNavModules',

  props: {
    isUserAdmin: Boolean
  },

  data: () => ({
    items: [
      {
        text: 'Usuarios',
        icon: mdiAccount,
        pathName: '/users'
      },
      {
        text: 'Publicaciones',
        icon: mdiStorefrontOutline,
        pathName: '/publications'
      },
      {
        text: 'Alquileres',
        icon: mdiHomeGroup,
        pathName: '/rentals'
      }
    ]
  }),

  methods: {
    checkRouteActive (route) {
      return route && this.$route.name ?
        route === '/' + this.$route.name.toString().toLocaleLowerCase()
          ? true
          : false
        : false
    }
  }

}
</script>

<style lang="scss" scoped>
.active {
  * {
    color: #F6C300 !important;
    fill: #F6C300 !important;
    font-weight: bold !important;
  }
  .v-list-item__icon {
    .v-icon{
      svg {
        path {
          color: #f6C300 !important;
          fill: #f6C300 !important;
        }
      }
    }
  }
}
</style>
