import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userToken: '',
    userDb: {},
    showMobileMenu: null,
    showLoading: false,
    alertOptions: { value: false, text: '', color: 'green darken-1' },
    textSearch: '',
    showAdvancedFilter: false,
    users: {
      textSearch: '',
      filterSearch: {
        status: ''
      }
    },
    publications: {
      textSearch: '',
      filterSearch: {
        status: '',
        user: ''
      }
    },
    rentals: {
      textSearch: '',
      filterSearch: {
        dateRange: [],
        user: ''
      }
    }
  },

  mutations: {
    setMobileMenu (state, val) {
      state.showMobileMenu = val
    },

    setSearchUserStatus (state, payload) {
      state.users.filterSearch.status = payload
    },

    setSearchPublicationStatus (state, payload) {
      state.publications.filterSearch.status = payload
    },

    setSearchPublicationUser (state, payload) {
      state.publications.filterSearch.user = payload
    },

    setSearchRentalDateRange (state, payload) {
      state.rentals.filterSearch.dateRange = payload
    },

    setSearchRentalUser (state, payload) {
      state.rentals.filterSearch.user = payload
    },

    setAdvancedFilter (state, val) {
      state.showAdvancedFilter = val
    },

    setUserStatus (state, payload) {
      if (payload === '') {
        state.userToken = ''
        state.userDb = {}
      } else {
        state.userToken = payload.token
        state.userDb = payload.user
      }
    },

    setLoading (state, payload) {
      state.showLoading = payload
    },

    setAlert (state, val) {
      state.alertOptions.value = val.value ? val.value : false
      state.alertOptions.text = val.text ? val.text : ''

      const colors = { success: 'green darken-1', error: 'red darken-1' }
      if (val.color) {
        state.alertOptions.color = colors[val.color] || colors.success
      }
    },

    setTextSearch (state, val) {
      state.textSearch = val
    }
  },
  actions: {
    handleMobileMenu ({ commit }, payload) {
      commit('setMobileMenu', payload)
    },

    handleSearchUserStatus ({ commit }, payload) {
      commit('setSearchUserStatus', payload)
    },

    handleSearchPublicationStatus ({ commit }, payload) {
      commit('setSearchPublicationStatus', payload)
    },

    handleSearchPublicationUser ({ commit }, payload) {
      commit('setSearchPublicationUser', payload)
    },

    handleSearchRentalDateRange ({ commit }, payload) {
      commit('setSearchRentalDateRange', payload)
    },

    handleSearchRentalUser ({ commit }, payload) {
      commit('setSearchRentalUser', payload)
    },

    handleAdvancedFilter ({ commit }, payload) {
      commit('setAdvancedFilter', payload)
    },

    saveUser ({ commit }, payload) {
      localStorage.setItem('userInfoToken', JSON.stringify(payload))
      commit('setUserStatus', payload)
    },

    signout ({ commit }) {
      commit('setUserStatus', '')
      localStorage.removeItem('userInfoToken')
    },

    checkUserLogged ({ commit }) {
      const payload = localStorage.getItem('userInfoToken')

      if (payload) {
        commit('setUserStatus', JSON.parse(payload))
      } else {
        commit('setUserStatus', '')
      }
    },

    handleLoading ({ commit }, payload) {
      commit('setLoading', payload)
    },

    handleAlert ({ commit }, payload) {
      commit('setAlert', payload)
    }
  },
  modules: {},
  getters: {
    getTextSearch: state => state.textSearch,

    mobileOpen: state => state.showMobileMenu,

    filterOpen: state => state.showAdvancedFilter,

    userLogged: state => !!state.userToken,

    userRoles: state => state.userDb.roles,

    alertOpen: state => state.alertOptions.value,

    filterUserStatus: state => state.users.filterSearch.status,

    searchUserText: state => state.users.textSearch,

    filterPublicationStatus: state => state.publications.filterSearch.status,

    filterPublicationUser: state => state.publications.filterSearch.user,

    searchPublicationText: state => state.publications.textSearch,

    filterRentalDateRange: state => state.rentals.filterSearch.dateRange,

    filterRentalUser: state => state.rentals.filterSearch.user,

    searchRentalText: state => state.rentals.textSearch
  }
})
