import axios from './instance'

const configToken = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

export const userLogin = async (body) => {
  try {
    const res = await axios.post('/api/login', body)
    return res
  } catch (err) {
    throw err.response
  }
}

export const userLogout = async (token) => {
  try {
    const res = await axios.post('/api/logout', null, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllUsers = async (token) => {
  try {
    const res = await axios.get('/api/users', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const getAllRoles = async (token) => {
  try {
    const res = await axios.get('/api/roles', configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const createUser = async (token, user) => {
  try {
    const res = await axios.post('/api/users', user, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const updateUser = async (token, user) => {
  try {
    const res = await axios.put(`/api/users/${user.id}`, user, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const deleteUser = async (token, id) => {
  try {
    const res = await axios.delete(`/api/users/${id}`, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

export const updateUserStatus = async (token, id, status) => {
  try {
    const res = await axios.put(`/api/users/change_status/${id}`, {"status": status}, configToken(token))
    return res
  } catch (err) {
    throw err.response
  }
}

