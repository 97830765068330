<script>
export default {
  name: 'DebounceHeaderSearch',
  methods: {
    debounceHeaderSearch (func, timeout = 500) {
      let timer
      return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => { func.apply(this, args) }, timeout)
      }
    }
  }
}
</script>

<style>

</style>
